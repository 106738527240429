<template>
  <v-container>
    <v-card class="my-4">
      <Filtros
        P_titulo
        P_status
        :P_statusArray="status"
        P_prioridade
        P_clienteServico
        P_dataColuna
        P_dataFrom
        P_dataTo
      />
    </v-card>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Atividades
      </v-card-title>

      <v-divider></v-divider>
      <v-card-title>
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="white--text"
          :loading="loading"
          :disabled="!listaAtividades.length"
          @click="gerarpdf"
        >
          relatório
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="listaAtividades"
          :search="search"
          :loading="loading"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToAtividadeCliente"
        >
          <template v-slot:item.status="{ item }">
            <v-icon
              class=""
              :color="
                item.status == 0
                  ? 'grey'
                  : item.status == 1
                  ? 'blue-grey'
                  : item.status == 2
                  ? 'yellow darken-4'
                  : item.status == 3
                  ? 'green'
                  : item.status == 4
                  ? 'purple'
                  : item.status == 5
                  ? 'green darken-4'
                  : item.status == 6
                  ? 'deep-purple accent-4'
                  : item.status == -1
                  ? 'red'
                  : 'light-green'
              "
              small
              left
              >mdi-square-rounded</v-icon
            >{{ item.status | statusAtividade(item.entrega) }}
          </template>
          <template v-slot:item.entrega="{ item }">
            {{ item.entrega | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.inicio="{ item }">
            {{ item.inicio | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.prioridade="{ item }">
            <div v-if="item.prioridade === 1">
              <v-icon color="prioridade_baixa"> mdi-flag </v-icon>
              Baixa
            </div>
            <div v-if="item.prioridade === 2">
              <v-icon color="prioridade_normal"> mdi-flag </v-icon>
              Normal
            </div>
            <div v-if="item.prioridade === 3">
              <v-icon color="prioridade_urgente"> mdi-flag </v-icon>
              Urgente
            </div>
            <div v-if="item.prioridade === 4">
              <v-icon color="prioridade_emergencia"> mdi-flag </v-icon>
              Emergência
            </div>
          </template></v-data-table
        >
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <ViewAtividadeCliente
      v-if="dialogAtividadeCliente"
      :dialogAtividadeCliente.sync="dialogAtividadeCliente"
      :item="atividade"
      @fetch-atividade="getAtividades"
    />
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="LISTA DE ATIVIDADES"
    />
  </v-container>
</template>

<script>
import { fetchAtividadesClientes } from "@/api/geral/atividades_clientes.js";
const pdfAtividadesListaPdf = () =>
  import(/* webpackChunkName: "relatorio" */ "./reports/atividadesListaPdf");
import { mapState } from "vuex";
export default {
  name: "Atividades",

  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
    ViewAtividadeCliente: () => import("./components/ViewAtividadeCliente.vue"),
    Filtros: () => import("@/components/filtros/Filtros.vue"),
  },

  data() {
    return {
      loading: false,
      dialogAtividadeCliente: false,
      atividade: {},
      search: "",
      pdfData: null,
      pdfDialog: false,
      listaAtividades: [],
      selectedStatus: null,
      menu: false,
      status: [
        { id: -1, tipo: "CANCELADO" },
        { id: 0, tipo: "A FAZER" },
        { id: 8, tipo: "ATRASADAS" },
        { id: 1, tipo: "EM ANDAMENTO" },
        { id: 2, tipo: "EM ESPERA" },
        { id: 3, tipo: "CONCLUÍDO AGUARDANDO-GESTOR" },
        { id: 4, tipo: "REFAZER - GESTOR" },
        { id: 5, tipo: "APROVADO AGUARDANDO-CLIENTE" },
        { id: 6, tipo: "REFAZER - CLIENTE" },
        { id: 7, tipo: "FINALIZADO" },
      ],
    };
  },
  watch: {
    url() {
      this.getAtividades();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Titulo",
          sortable: false,
          align: "left",
          value: "titulo",
        },
        {
          text: "serviço",
          sortable: false,
          value: "servico.descricao",
        },
        {
          text: "início",
          sortable: true,
          value: "inicio",
        },
        {
          text: "entrega",
          sortable: false,
          value: "entrega",
        },
        {
          text: "Status",
          sortable: true,
          value: "status",
        },
        {
          text: "prioridade",
          sortable: true,

          value: "prioridade",
        },
        // {
        //   text: "Actions",
        //   value: "",
        // },
      ];
    },
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?user_id=${this.user_id}${queryString}`;
    },
  },

  methods: {
    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfAtividadesListaPdf()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.listaAtividades).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
    goToAtividadeCliente(item) {
      this.atividade = { ...item };
      this.dialogAtividadeCliente = true;
    },
    getAtividades() {
      if (this.user_id) {
        this.loading = true;
        return fetchAtividadesClientes(`${this.url}`)
          .then((response) => {
            this.listaAtividades = response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getAtividades();
  },
};
</script>

<style lang="scss"></style>
