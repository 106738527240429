import api from "../axios_service.js";

export async function fetchAtividadesClientes(filtros) {
  const response = await api.get(
    `/atividades-clientes${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export function putAtividadeCliente(id, body) {
  return api.put(`/atividades/${id}`, body);
}

export async function fetchAtividadesColaborador(filtros) {
  const response = await api.get(
    `/atividades-colaborador${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchAtividade(id) {
  const response = await api.get(`/atividade/${id}`);
  return response.data.result;
}
